<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Button type="primary" @click="handleCreateMemberType">添加类型</Button>
      <Table
        class="l-m-t25"
        :columns="columns"
        :data="dataList"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="is_del">
          <i-switch
            v-model="row.is_del"
            :value="row.is_del"
            :true-value="0"
            :false-value="1"
            @on-change="handleChangeIsShow(row)"
            size="large">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <a @click="editType(row)">编辑</a>
          <Divider type="vertical" v-if="row.type !== 'free' && row.type !== 'ever'" />
          <a class="l-color-error" v-if="row.type !== 'free' && row.type !== 'ever'" @click="del(row, '删除类型', index)">删除</a>
        </template>
      </Table>
    </Card>
    <Modal
      v-model="modal"
      :title="`${rowModelType}${rowEdit && rowEdit.title}会员`"
      footer-hide
      @on-cancel="handleCancel">
      <form-create v-model="fapi" :rule="rule" @submit="handleSubmit"></form-create>
    </Modal>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getUsersMemberShipTypeApi
} from '@/api/marketing'

export default {
  name: 'type',
  mixins: [mixins],
  data () {
    return {
      // 表格数据
      dataList: [],
      columns: [
        { title: 'ID', key: 'id' },
        { title: '会员名', key: 'title' },
        {
          title: '有限期（天）',
          key: 'vip_day',
          render: (h, params) => {
            return h('span', params.row.vip_day === -1 ? '永久' : params.row.vip_day)
          }
        },
        { title: '原价', key: 'price' },
        { title: '优惠价', key: 'pre_price' },
        { title: '排序', key: 'sort' },
        { title: '是否开启', slot: 'is_del' },
        { title: '操作', slot: 'action' }
      ],

      // 添加表单数据
      modal: false,
      rowEdit: {},
      rowModelType: '编辑',
      rule: [
        {
          type: 'hidden',
          field: 'id',
          value: ''
        },
        {
          type: 'hidden',
          field: 'type',
          value: ''
        },
        {
          type: 'input',
          field: 'title',
          title: '会员名',
          value: '',
          props: {
            disabled: false,
            placeholder: '输入会员名'
          },
          validate: [
            {
              type: 'string',
              max: 10,
              min: 1,
              message: '请输入长度为1-10的名称',
              requred: true
            }
          ]
        },
        {
          type: 'InputNumber',
          field: 'vip_day',
          title: '有限期（天）',
          value: null,
          props: {
            precision: 0,
            disabled: false,
            type: 'text',
            placeholder: '输入有限期'
          },
          validate: [
            {
              type: 'number',
              max: 1000000,
              min: 0,
              message: '最大只能输入1000000,最小为0',
              requred: true
            }
          ]
        },
        {
          type: 'InputNumber',
          field: 'price',
          title: '原价',
          value: null,
          props: {
            min: 0,
            disabled: false,
            placeholder: '输入原价'
          },
          validate: [
            {
              type: 'number',
              max: 1000000,
              min: 0,
              message: '最大只能输入1000000,最小为0',
              requred: true
            }
          ]
        },
        {
          type: 'InputNumber',
          field: 'pre_price',
          title: '优惠价',
          value: null,
          props: {
            min: 0,
            disabled: false,
            placeholder: '输入优惠价'
          },
          validate: [
            {
              type: 'number',
              max: 1000000,
              min: 0,
              message: '最大只能输入1000000,最小为0',
              requred: true
            }
          ]
        },
        {
          type: 'InputNumber',
          field: 'sort',
          title: '排序',
          value: 0,
          props: {
            min: 1,
            max: 1000000,
            disabled: false,
            placeholder: '请输入排序'
          },
          validate: [
            {
              type: 'number',
              max: 1000000,
              min: 0,
              message: '最大只能输入1000000,最小为0',
              requred: true
            }
          ]
        }
      ],
      fapi: {
        id: '',
        pre_price: null,
        price: null,
        sort: null,
        title: '',
        type: 'owner',
        vip_day: null
      }
    }
  },
  mounted () {
    this.getMemberTypeRequest()
  },
  methods: {
    // 获取会员类型
    getMemberTypeRequest () {
      this.loading = true
      getUsersMemberShipTypeApi().then((res) => {
        this.loading = false
        const { count, list } = res.data
        this.dataList = list
        this.total = count
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 添加新会员类型
    handleCreateMemberType () {
      this.rowEdit.id = 0
      this.rowModelType = '新增'
      this.rule[1].value = 'owner'
      this.rule[3].props.disabled = false
      this.rule[5].props.disabled = false
      this.rowEdit.title = ''
      this.modal = true
    },
    // 处理状态修改
    handleChangeIsShow () {},
    // 处理表单关闭
    handleCancel () {
      this.fapi.resetFields()
    },
    // 提交数据保存
    handleSubmit () {}
  }
}
</script>

<style scoped>

</style>
